<template>
  <div class="commission MERCH">
    <div class="merch-card">
      <!-- tag标签 -->
      <div class="merch-tag">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="免息期配置" name="0"> </el-tab-pane>
          <el-tab-pane label="白条佣金配置" name="1"></el-tab-pane>
        </el-tabs>
      </div>
      <div>
        <div class="comm" v-if="activeName == 0">
          <!-- <div style="margin-top: 20px">
            修改后已经设置免息期的供应商不受影响，新注册是供应商按照最新免息期进行设置。
          </div> -->
          <div>
            <div class="header">
              <div
                class="box-imp"
                v-for="(item, index) in CommissionMessage"
                :key="index"
              >
                <span class="span-text">免息时间：</span>
                <el-input
                  class="input-2"
                  v-model="item.month"
                  type="text"
                  placeholder="请输入"
                />
                <span class="bfb">个月;</span>
                <span class="span-text">最低分佣比例：</span>
                <el-input
                  @change="item.commission < 0 ? (item.commission = 1) : ''"
                  class="input-2"
                  v-model="item.commission"
                  type="text"
                  placeholder="请输入"
                />
                <span class="bfb">%</span>
                <span
                  @click="delCom(index)"
                  v-if="index != 0"
                  class="span-text icoed"
                  ><i class="el-icon-remove-outline"></i
                ></span>
              </div>
              <div class="txted">
                温馨提示:修改后已经设置免息期的供应商不受影响，新注册是供应商按照最新免息期进行设置。
              </div>
            </div>
            <div class="operate-btn" style="margin-left: 5%">
              <el-button @click="AddCom" class="btn06b">新增免息期</el-button>
              <el-button @click="save" type="primary">保存</el-button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeName == 1" style="margin: 55px 0px 0px 0px">
        <div class="comeds">
          <el-form
            ref="Whitesitem"
            :model="Whitesitem"
            label-width="150px"
            :rules="rules"
          >
            <el-form-item label="白条佣金出资方：" prop="investor">
              <el-radio-group v-model="Whitesitem.investor">
                <el-radio
                  v-for="(item, index) in investorList"
                  :key="index"
                  :label="item.id"
                  >{{ item.name }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="平台出资比例："
              v-if="Whitesitem.investor == 2"
              prop="platformProvidePercent"
            >
              <el-input
                maxlength="32"
                v-model="Whitesitem.platformProvidePercent"
                placeholder="请输入平台出资比例"
                @input="
                  Whitesitem.platformProvidePercent =
                    Whitesitem.platformProvidePercent.replace(
                      /^(0+)|[^\d]+/g,
                      ''
                    )
                "
                clearable
                style="width: 250px"
              />
              <span>&emsp;%</span>
            </el-form-item>
            <el-form-item label="白条佣金基数对象：" prop="commisionFrom">
              <el-radio-group v-model="Whitesitem.commisionFrom">
                <el-radio :label="0">订单金额</el-radio>
                <el-radio :label="1">实付金额</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="白条分佣比例：" prop="commissionPercent">
              <el-input
                maxlength="32"
                v-model="Whitesitem.commissionPercent"
                @input="
                  Whitesitem.commissionPercent =
                    Whitesitem.commissionPercent.replace(/^(0+)|[^\d]+/g, '')
                "
                placeholder="请输入分佣比例"
                clearable
                style="width: 250px"
              />
              <span>&emsp;%</span>
            </el-form-item>
            <el-form-item label="白条分佣解绑条件：" prop="unboundType">
              <el-select
                clearable
                v-model="Whitesitem.unboundType"
                filterable
                placeholder="请选择"
                style="width: 250px"
              >
                <el-option
                  v-for="(item, index) in conditionList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label="白条分佣解绑时效："
              v-if="Whitesitem.unboundType != 3"
              prop="bindingDays"
            >
              <el-input
                maxlength="32"
                v-model="Whitesitem.bindingDays"
                placeholder="请输入分佣解绑时效"
                @input="
                  Whitesitem.bindingDays = Whitesitem.bindingDays.replace(
                    /^(0+)|[^\d]+/g,
                    ''
                  )
                "
                clearable
                style="width: 250px"
              />
              <span>&emsp;天</span>
            </el-form-item>
            <!-- <el-form-item label="跟随平台设置变动：" prop="standardFrom">
            <el-switch
              v-model="Whitesitem.standardFrom"
              active-color="#13ce66"
              :active-value="1"
              :inactive-value="2"
            ></el-switch>
          </el-form-item> -->
          </el-form>
        </div>
        <div class="newbtn">
          <el-button class="newsd" @click="conserve" type="primary"
            >更 新</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Basics");
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
export default {
  data() {
    return {
      activeName: "0",
      content: {
        content: "",
      },
      CommissionMessage: [],
      Whitesitem: {
        investor: 0,
      }, //白条分佣设置
      investorList: [
        {
          name: "平台出资",
          id: 0,
        },
        {
          name: "供应商出资",
          id: 1,
        },
        {
          name: "双方出资",
          id: 2,
        },
      ], //白条佣金出资方
      bothsides: false, //是否为双方出资
      conditionList: [
        {
          name: "时效内未使用白条",
          id: 1,
        },
        {
          name: "时效内未发生交易",
          id: 2,
        },
        {
          name: "不解绑",
          id: 3,
        },
      ], //白条分佣解绑条件
      rules: {
        investor: [
          { required: true, message: "请选择白条佣金出资方", trigger: "blur" },
        ],
        platformProvidePercent: [
          { required: true, message: "请选择平台出资比例", trigger: "blur" },
        ],
        commisionFrom: [
          {
            required: true,
            message: "请选择白条佣金基数对象",
            trigger: "blur",
          },
        ],
        commissionPercent: [
          { required: true, message: "请输入白条分佣比例", trigger: "blur" },
        ],
        unboundType: [
          {
            required: true,
            message: "请选择白条分佣解绑条件",
            trigger: "blur",
          },
        ],
        bindingDays: [
          { required: true, message: "请输入分佣解绑时效", trigger: "blur" },
        ],
        standardFrom: [
          {
            required: true,
            message: "请选择是否跟随平台设置变动",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    "Whitesitem.investor": {
      handler(oldVal) {
        if (oldVal == 2) {
          this.bothsides = true;
        } else {
          this.bothsides = false;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...commonIndex.mapState(["allJurisdiction"]),
  },
  created() {
    this.getMessage();
  },
  methods: {
    ...mapActions([
      "getloanCommissionlist",
      "postloanCommissionsave",
      "getPlatformIOU",
      "updatePlatformIOU",
    ]),
    // 保存平台免息
    async save() {
      let newData = this.CommissionMessage.filter((item) => {
        return item.commission == "" || item.month == "";
      });
      if (newData.length > 0) {
        this.$message({
          message: `新增之后必须填写`,
          type: "warning",
        });
        return;
      }
      let data = await this.postloanCommissionsave(this.CommissionMessage);
      if (data.code == 200) {
        this.getMessage();
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 删除一条数据
    async delCom(index) {
      this.CommissionMessage.splice(index, 1);
    },
    // 新增一条
    AddCom() {
      this.CommissionMessage.push({ month: "", commission: "" });
    },
    // 获取白条信息
    async getMessage() {
      let data = await this.getloanCommissionlist();
      if (data.data.length > 0) {
        this.CommissionMessage = data.data;
      } else {
        this.CommissionMessage = [{ month: "", commission: "" }];
      }
    },
    handleCurrentChange() {},
    toSearch() {},
    // tag标签切换
    async handleClick(tab, event) {
      if (this.activeName == 1) {
        let item = await this.getPlatformIOU({});
        this.Whitesitem = { ...item.data };
      }
    },
    // 更新
    conserve() {
      this.$refs["Whitesitem"].validate((valid) => {
        if (valid) {
          if (
            this.Whitesitem.investor == 2 &&
            this.Whitesitem.platformProvidePercent > 100
          )
            return this.$message.error("出资比例不能大于100");
          if (
            this.Whitesitem.investor != 2 &&
            this.Whitesitem.commissionPercent > 100
          )
            return this.$message.error("白条分佣比例不能大于100");
          this.updatePlatformIOU(this.Whitesitem).then((res) => {
            if (res.code == 200) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 关闭
    toleave() {},
    // 富文本类容
    onEditorChange() {},
  },
};
</script>
<style lang="scss" scoped>
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    height: 100%;
    min-height: 600px;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    .box-imp {
      padding: 25px 0px;
      width: 100%;
      display: flex;
      color: #666666;
      .bfb {
        font-size: 14px;
        line-height: 30px;
        margin-left: 10px;
      }
      .span-text {
        margin-left: 20px;
        font-size: 14px;
        line-height: 30px;
        cursor: pointer;
      }
      .icoed {
        font-size: 22px;
        color: #06b7ae;
        margin-left: 25px;
      }
      .input-2 {
        margin-left: 10px;
        width: 240px;
        height: 32px;
        margin-top: -5px;
      }
    }
  }
  .comm {
    width: 100%;
    height: 62vh;
    text-align: center;
    color: #999;
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}
.header {
  height: 60vh;
  overflow: auto;
}
::-webkit-scrollbar {
  width: 0;
}

.txted {
  color: #f53f3f;
  font-size: 12px;
  margin-top: 15px;
}
.btn06b {
  border: 1px solid #06b7ae;
  color: #06b7ae;
  margin-right: 20px;
}
.comeds {
  display: flex;
  justify-content: center;
  height: 60vh;
  color: #666666;
}
.newbtn {
  display: flex;
  justify-content: center;
  .newsd {
    text-align: center;
  }
}
</style>
